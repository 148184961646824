import React from "react";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import LightSpeed from "react-reveal/LightSpeed";
import imgLaptop from "../../static/images/laptop.png";
import imgNum1 from "../../static/images/1.png";
import imgNum2 from "../../static/images/2.png";
import imgNum3 from "../../static/images/3.png";

export default function Process() {
  return (
    <section
      id="servicios"
      className="h-100 tint-bg img-bg-soft img-bg img-bg-process pb-5"
    >
      <div id="telecom" className="bg-telecom" />
      <div className="container pt-5 ">
        <div className="row">
          <div className="col-md-10 offset-md-1 pt-4 pb-4">
            <h1 className="white-title pb-3">Telecomunicaciones</h1>
            <div className="row">
              <div className="col-md-6">
                <ul class="list-unstyled">
                  <li>
                    <p className="lead mb-0 white-title-sub">
                      <i class="fa fa-caret-right" aria-hidden="true" />{" "}
                      Cableado estructurado y equipamiento para redes de voz y
                      datos
                    </p>
                  </li>
                  <li>
                    <p className="lead mb-0 white-title-sub">
                      <i class="fa fa-caret-right" aria-hidden="true" /> Fibra
                      óptica
                    </p>
                  </li>
                  <li>
                    <p className="lead mb-0 white-title-sub">
                      <i class="fa fa-caret-right" aria-hidden="true" /> Control
                      de accesos
                    </p>
                  </li>
                  <li>
                    <p className="lead mb-0 white-title-sub">
                      <i class="fa fa-caret-right" aria-hidden="true" />{" "}
                      Videovigilancia
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul class="list-unstyled">
                  <li>
                    <p className="lead mb-0 white-title-sub">
                      <i class="fa fa-caret-right" aria-hidden="true" />{" "}
                      Detección de incendios
                    </p>
                  </li>
                  <li>
                    <p className="lead mb-0 white-title-sub">
                      <i class="fa fa-caret-right" aria-hidden="true" /> IPTV
                    </p>
                  </li>
                  <li>
                    <p className="lead mb-0 white-title-sub">
                      <i class="fa fa-caret-right" aria-hidden="true" /> Sonido
                      (voceo)
                    </p>
                  </li>
                  <li>
                    <p className="lead mb-0 white-title-sub">
                      <i class="fa fa-caret-right" aria-hidden="true" />{" "}
                      Telemedicina
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
