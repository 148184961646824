import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import proy1 from "../../static/images/proy1.jpg";
import proy2 from "../../static/images/policlinica.png";
import proy3 from "../../static/images/inger.jpg";

export default function Projects() {
  const [verMasProy, setVerMasProy] = useState(false);
  const handleVerMas = () => {
    setVerMasProy(true);
  };
  return (
    <section id="nosotros" className="pt-5 pb-3">
      <div className="container inner-top inner-bottom-sm">
        <Fade bottom>
          <div className="row">
            <div
              className="col-lg-8 col-md-9 mx-auto text-center aos-init aos-animate pb-4"
              data-aos="fade-up"
            >
              <h1 className="blue-text">Algunos proyectos que nos respaldan</h1>
            </div>
          </div>
        </Fade>
        <div className="row">
          <div className="container">
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <div className="col-md-4">
                  <div className="card mb-3">
                    <img src={proy2} alt="Card image cap" />
                    <div className="card-body">
                      <span className="green-sub sub-small">Instalación</span>
                      <h4 className="blue-text title-small">
                        Policlínica Nuestra señora del carmen, CDMX
                      </h4>
                      <p className="card-text">
                        Instalación de sistemas de telecomunicaciones: CCTV,
                        control de accesos, detección de incendios, audio, voz y
                        datos, telemedicina, IPTV
                        <i className="fa fa-arrow-right float-right green-text" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card mb-3">
                    <img src={proy1} alt="Card image cap" />
                    <div className="card-body">
                      <span className="green-sub sub-small">Instalación</span>
                      <h4 className="blue-text title-small">
                        Cooperativa Cruz Azul
                      </h4>
                      <p className="card-text">
                        Instalación de voz y datos, circuito cerrado de
                        televisión y detección de incendios para proyecto de
                        remodelación de oficinas de Concretos Cruz Azul
                        <i className="fa fa-arrow-right float-right green-text" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card mb-3">
                    <img src={proy3} alt="Card image cap" />
                    <div className="card-body">
                      <span className="green-sub sub-small">Instalación</span>
                      <h4 className="blue-text title-small">
                        Secretaría de Salud
                      </h4>
                      <p className="card-text">
                        Instalaciones de voz y datos, circuito cerrado de TV y
                        detección de incendios para proyecto integral de
                        rehabilitación para oficinas de los edificios 5, 6 y 7
                        de la zona "B" en el Instituto Nacional de Geriatría
                        (INGER)
                        <i className="fa fa-arrow-right float-right green-text" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <p>
                  <button
                    className="btn btn-primary-outline"
                    onClick={handleVerMas}
                  >
                    Ver más
                  </button>
                </p>
                {verMasProy && (
                  <div className="mas-proyectos">
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Cooperativa Cruz Azul
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Instalación de sistemas de telecomunicaciones:
                          Instalación de sistema de detección de incendios voz y
                          datos, CCTV, alarma sísmica y televisión para las
                          aulas corporativas
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Cooperativa Cruz Azul
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Instalación de sistema de corriente ininterrumpida
                          para proyecto de remodelación de oficinas de Concretos
                          Cruz Azul
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Oficinas administrativas Selanusa, CDMX
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Instalación de sistemas de telecomunicaciones: CCTV,
                          voz y datos
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Cooperativa Cruz Azul
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Instalación de sistemas de telecomunicaciones:
                          Instalación de sistema de detección de incendios para
                          todas las áreas del edificio corporativo como parte de
                          la actualización tecnológica de los sistemas de
                          Seguridad.
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Oficinas corporativa despacho MC Proyectos
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Instalación de sistemas de telecomunicaciones: CCTV,
                          voz y datos
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Oficina corporativa capacitación empresarial s.a. de
                          c.v.
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Instalación de sistemas de telecomunicaciones: CCTV
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Aeropuerto internacional de la CDMX
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Diseño del proyecto de detección de incendios para la
                          ampliación en le Terminal 2 para las posiciones de
                          contacto en dedo "L"
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Aeropuerto internacional de toluca, estado de méxico
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Diseño de proyecto de instalaciones de
                          telecomunicaciones para la reubicación y reintegración
                          del centro de monitoreo de CCTV
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Aeropuerto internacional de toluca, estado de méxico
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Proyecto de instalaciones de telecomunicaciones para
                          la ampliación de aeropasillos
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">AICM T2</p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Estudio y proyecto de desvío de la red de fibra óptica
                          principal derivado de la ampliación de la sala 75 de
                          la Terminal 2
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Aeropuerto de ixtepec, oaxaca
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Diseño de proyecto de instalaciones de voz y datos
                          para las oficinas de despacho e información de vuelo
                          (ODIV) y remodelación de la estación metereológica
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">Grupo ÁNGELES</p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Mantenimiento preventivo y correctivo del sistema de
                          detección de incendios de la Unidad Médica Toluca,
                          Estado de México.
                        </p>
                      </div>
                    </div>
                    <div className="row item-proy">
                      <div className="col-md-4">
                        <p className="blue-text proy-title">
                          Fresenius medical care de méxico
                        </p>
                      </div>
                      <div className="col-md-8 proy-description">
                        <p>
                          Mantenimiento preventivo y correctivo del sistema de
                          detección de incendios de Unidad Médico Puebla
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
