import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
import iconHeader from "../../static/images/icon-header.png";
import videoIntro from "../../static/video/hevasa_web.mp4";
import videoPoster from "../../static/images/poster.png";

export default function Header() {
  return (
    <header id="inicio" className="bg-blue">
      <div className="masthead bg-1">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-6 offset-md-1 pt-5">
              <h1 className="font-weight-black pt-5">
                Integración de <br /> <strong>Telecomunicaciones</strong>
              </h1>
              <div className="row">
                <div className="col-lg-1 col-2">
                  <img src={iconHeader} alt="" className="icon-header" />
                </div>
                <div className="col-lg-6 col-9">
                  <h6 className="green-header">Soluciones vanguardistas</h6>
                  <p className="white-text text-small">
                    En Diseño e Infraestructura Tecnológica. Asesoramos a
                    nuestros clientes con la mejor toma de decisiones en
                    inversión y desarrollo de áreas de TI
                  </p>
                  <li className="green-header list-unstyled">
                    <ScrollLink
                      className="green-header"
                      to="contactanos"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={2500}
                    >
                      Contacto <i className="fa fa-chevron-right pl-2" />
                    </ScrollLink>
                  </li>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div class="embed-responsive embed-responsive-4by3 mt-5">
                <video width="320" height="240" controls poster={videoPoster}>
                  <source src={videoIntro} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-md-10 offset-md-1">
              <nav className="nav-bottom">
                <ul>
                  <li>
                    <ScrollLink
                      activeClass="active"
                      className="nav-link"
                      to="inicio"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={2500}
                    >
                      INICIO
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      activeClass="active"
                      className="nav-link"
                      to="nosotros"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={2500}
                    >
                      NOSOTROS
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      activeClass="active"
                      className="nav-link"
                      to="servicios"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={2500}
                    >
                      SERVICIOS
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      activeClass="active"
                      className="nav-link"
                      to="clientes"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={2500}
                    >
                      NUESTRO TRABAJO
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      activeClass="active"
                      className="nav-link"
                      to="contactanos"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={2500}
                    >
                      CONTACTO
                    </ScrollLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
