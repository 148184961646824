import React from "react";
import imgWifi from "../../static/images/redeswifi.jpg";
import imgFibra from "../../static/images/fibra.jpg";

export default function ServicesDetail() {
  return (
    <section>
      <div className="row mx-0">
        <div className="col-md-6 px-0">
          <div className="bg-voz-datos">
            <div className="datos-span py-2 px-3">
              <span>
                <i class="fa fa-caret-right" aria-hidden="true" /> Cableado
                estructurado y equipamiento <br />
                para redes de voz y datos
              </span>
            </div>
          </div>
          <div className="">
            <div className="row mx-0">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <div className="img-minus-margin-2 text-center">
                  <img
                    className="img-fluid img-circular"
                    src={imgFibra}
                    alt=""
                  />
                  <span>
                    <i class="fa fa-caret-right" aria-hidden="true" /> Fibra
                    óptica
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 px-0 h-100">
          <div className="row mx-0">
            <div className="col-md-6 px-0">
              <div className="bg-accesos">
                <span class="green-span d-flex">
                  <i class="fa fa-caret-right" aria-hidden="true" /> Control de
                  accesos
                </span>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="bg-iptv">
                <span class="green-span d-flex">
                  <i class="fa fa-caret-right" aria-hidden="true" /> IPTV
                </span>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="bg-vigilancia">
                <span class="green-span d-flex">
                  <i class="fa fa-caret-right" aria-hidden="true" />{" "}
                  Videovigilancia
                </span>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="bg-sonido">
                <span class="green-span d-flex">
                  <i class="fa fa-caret-right" aria-hidden="true" /> Sonido
                  (voceo)
                </span>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="bg-incendios">
                <span class="green-span d-flex">
                  <i class="fa fa-caret-right" aria-hidden="true" /> Detección
                  de incendios
                </span>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="bg-videoconferencia">
                <span class="green-span d-flex">
                  <i class="fa fa-caret-right" aria-hidden="true" />{" "}
                  Telemedicina
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
