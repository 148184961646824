import React from "react";
import { Link as ScrollLink } from "react-scroll";

const CTA = () => {
  return (
    <section className="shadow">
      <div className="container pt-5 pb-5">
        <div className="col-md-12 text-center">
          <h1>Hable con nosotros</h1>
          <small>
            Un gran equipo nos respalda para llevar sus proyectos al siguiente
            nivel
          </small>
          <br />
          <ScrollLink
            activeClass="active"
            className="btn btn-primary-outline"
            to="contactanos"
            spy={true}
            smooth={true}
            offset={-80}
            duration={2500}
          >
            Contacto
          </ScrollLink>
          <br />
        </div>
      </div>
    </section>
  );
};

export default CTA;
