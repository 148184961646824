import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import About from "../components/about";
import Team from "../components/team";
import Process from "../components/process";
import Clients from "../components/clients";
import Projects from "../components/projects";
import Map from "../components/map";
import CTA from "../components/cta";
import ServicesDetail from "../components/servicesDetail";

export const query = graphql`
  query GET_CLIENTS {
    clients: allFile(filter: { relativeDirectory: { eq: "clients" } }) {
      edges {
        node {
          id
          publicURL
          size
          name
          absolutePath
          dir
        }
      }
    }
    marcas: allFile(filter: { relativeDirectory: { eq: "marcas" } }) {
      edges {
        node {
          id
          publicURL
          size
          name
          absolutePath
          dir
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Hevasa - Integración de Telecomunicaciones" />

    <Header />

    <About />

    <Clients marcas={data.marcas.edges} />

    <Process />

    <ServicesDetail />

    <CTA />

    <Projects />

    {/* <Testimonial /> */}

    <Map />
  </Layout>
);

export default IndexPage;
