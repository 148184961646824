import React from "react";
import Fade from "react-reveal/Fade";
import { Link as ScrollLink } from "react-scroll";
import imgLap from "../../static/images/lap.png";
import imgServices from "../../static/images/servicios.png";

export default function About() {
  return (
    <section id="nosotros" className="about">
      <div className="inner-top inner-bottom-sm">
        <div className="row m-0">
          <div className="col-md-5 offset-md-1 pt-4 pb-4">
            <span className="pre-header">Acerca de </span>
            <h1 className="white-title">Nosotros</h1>
            <p className="p-white">
              <span className="text-uppercase">Telecom Hevasa</span> es una
              empresa constituida por profesionales de la industria de
              Telecomunicaciones, debido a la constante interacción y
              participación que cada uno de nosotros ha tenido con diversos
              clientes y protagonistas de las Telecomunicaciones en nuestro
              país, decidimos emprender este proyecto que al día de hoy ha
              rendido el fruto que demuestran nuestros clientes con una gran
              confianza derivada de nuestra buena actitud y profesionalismo al
              desarrollar las instalaciones.
            </p>

            {/* <img src={imgLogoPencil} alt='' className='img-fluid' /> */}
          </div>
          <div className="col-md-6 bg-blue-services">
            <div className="h-100 d-flex flex-wrap align-items-center">
              <img src={imgServices} alt="" className="img-fluid" />
            </div>
          </div>
        </div>

        <div className="row"></div>
      </div>
    </section>
  );
}
