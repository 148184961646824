import React from "react";

export default function Map() {
  return (
    <section id="contactanos" className="mapa">
      <div className="container">
        <div className="row">
          <div className="col-md-5 offset-md-1 col-12 pt-4">
            <h6 className="blue-text text-uppercase">Teléfono</h6>
            <p className="white-text small"> (55) 1563 3024</p>
            <form>
              <h6 className="blue-text text-uppercase">Deja tus comentarios</h6>
              <div className="form-group row">
                <label className="col-sm-2 white-text small">Nombre*</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" placeholder="" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 white-text small">Email*</label>
                <div className="col-sm-10">
                  <input type="email" className="form-control" placeholder="" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 white-text small">Empresa*</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" placeholder="" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 white-text small">Teléfono</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" placeholder="" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 white-text small">Mensaje*</label>
                <div className="col-sm-10">
                  <textarea className="form-control" rows="3" />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <span className="white-text small">* Campo requerido</span>
                  <button
                    type="submit"
                    className="float-right btn btn-white-outline text-uppercase"
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-5 col-12">
            <iframe
              title="mapa"
              frameBorder="0"
              height="750"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.5287886317!2d-99.17109208569981!3d19.302847386958184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0068125cbac5%3A0xe1e7d382cc0ddd40!2zQyZBIEPDoXJkZW5hcywgUy5DLiwgUGFyw61zIDE5MDAgMTIsIE9sw61tcGljYSwgQ295b2Fjw6FuLCAwNDcxMCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWA!5e0!3m2!1ses-419!2smx!4v1577473790972!5m2!1ses-419!2smx"
              style={{ border: 0 }}
              width="100%"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
