import React from "react";
import Fade from "react-reveal/Fade";
import ClientSlider from "./clientSlider";

export default function Clients({ marcas }) {
  return (
    <section id="clientes">
      <div className="bg-distribucion"></div>
      <div className="pt-5 pb-3 client-bg">
        <div className="container inner-top inner-bottom-sm">
          <Fade bottom>
            <div className="row">
              <div
                className="col-lg-8 col-md-9 mx-auto text-center aos-init aos-animate pb-4"
                data-aos="fade-up"
              >
                <h1 className="green-title">
                  Distribución y Venta de <br />
                  <span className="blue-text text-uppercase">
                    Equipos para Sistemas
                  </span>
                  <br />
                  de Alta Tecnología
                </h1>
              </div>
            </div>
          </Fade>
          <div className="row">
            <div className="col-lg-12 pb-4">
              <ClientSlider imagenes={marcas} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
